import React from "react"
import { graphql } from "gatsby"
import parse from "html-react-parser"

export default function ProjektTemplate({ data }) {
  const projekt = data.wpProjekt

  return (
    <div className="container">
      <h1>{projekt.title}</h1>
      {projekt.content && parse(projekt.content)}
    </div>
  )
}

export const query = graphql`
  query($id: String!) {
    wpProjekt(id: { eq: $id }) {
      title
      content
    }
  }
`
